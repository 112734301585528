import React, { useState, useEffect } from "react";
import Link from "next/link";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import Fuse from "fuse.js";
import * as FormData from "form-data";
import { authHeader } from "../_helpers";



function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}
function searchWithFuse(options, inputValue) {
  const configOptions = {
    useExtendedSearch: true,
    ignoreLocation: true,
    keys: ["name", "search_keywords"],
  };
  updateSearchKeyword(inputValue);
  const fuse = new Fuse(options, configOptions);
  inputValue = inputValue ? "'" + inputValue : "sell";
  var res = fuse.search(inputValue);
  var finalOption = Object.keys(res).map((key) => res[key].item);
  //console.log(finalOption);
  return finalOption;
}

const updateSearchKeyword = async (inputValue) => {
  var formData = new FormData();
  formData.append("keyword", inputValue);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };
  const apiUrl = `${process.env.NEXT_PUBLIC_API_URL}products/updateSearchedKeyword`;

  // Fetch data from external API
  const res = await fetch(apiUrl, requestOptions);
};

export default function ProductSearch() {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const apiUrl = `${process.env.NEXT_PUBLIC_API_URL}products/allSearchableModels/X-API-KEY/${process.env.NEXT_PUBLIC_API_KEY}`;
      const response = await fetch(apiUrl);
      const products = await response.json();

      if (active) {
        setOptions(products);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const filterOptions = (options, { inputValue }) =>
    searchWithFuse(options, inputValue);
  

  return (
    <Autocomplete
      id="model-search"
      style={{ border:'none' }}
      debug={true}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      filterOptions={filterOptions}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField 
          {...params}
          label="" 
          placeholder="Sell your used mobile, tablet, laptop or other gadget"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            className: "custom-placeholder",
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          onBlur={(e) => {
            updateSearchKeyword(e.target.value);
          }}
        />
      )}
      renderOption={(option, { inputValue }) => {
        const matches = match(option.name, inputValue);
        const parts = parse(option.name, matches);

        return (
          <a href={`/${option.url}`} style={{ width: "100%" }}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </a>
        );
      }}
    />
  );
}
