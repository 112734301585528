import React, { Component } from "react";
import Link from "next/link";
import { Button } from "react-bootstrap";
import ProductSearch from "./ProductSearch";
import styles from "../styles/Home.module.css";
import Image from "next/image";


class Searchbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { innerPage } = this.props;
    return (
      <div className={styles.banner}>
        {innerPage ? (
          <>
            
          </>
        ) : (
          <>
          <div className="container-fluid">
            <div className="sellbnr rounded-1rem" style={{backgroundImage: 'url(/images/sellbannerbg.webp)'}}>
              <div className="col-lg-10 mx-auto">
                <div className="row align-items-center">
                  <div className="col-md-6 mb-3 mb-md-0 text-center px-lg-5">
                    <h1 className="mb-4 fw-600 text-default-red h4">Sell Old Gadget at Best Prices</h1>
                    <div className="px-lg-5">
                      <div className="row mob-row5 sellwidgt mb-4">
                        <div className="col-3">
                          <Link href="/sell-used-mobile-phones">
                            <a className="text-white p-2 w-100 h-100 rounded d-flex flex-column align-items-center justify-content-center gap-1 text-center">
                            <img className="w-100" src={`/images/sell-mob.svg`} />
                            <small>Sell Mobile</small>
                            </a>
                          </Link>
                        </div>
                        <div className="col-3">
                          <Link href="/sell-used-tablets">
                            <a className="text-white p-2 w-100 h-100 rounded d-flex flex-column align-items-center justify-content-center gap-1 text-center">
                              <img className="w-100" src={`/images/sell-tab.svg`} />
                              <small>Sell Tablet</small>
                            </a>
                          </Link>
                        </div>
                        <div className="col-3">
                          <Link href="/sell-used-laptops">
                            <a className="text-white p-2 w-100 h-100 rounded d-flex flex-column align-items-center justify-content-center gap-1 text-center">
                            <img className="w-100" src={`/images/sell-lap.svg`} />
                            <small>Sell Laptop</small>
                            </a>
                          </Link>
                        </div>
                        <div className="col-3">
                          <Link href="/sell-used-othergadgets">
                            <a className="text-white p-2 w-100 h-100 rounded d-flex flex-column align-items-center justify-content-center gap-1 text-center">
                            <img className="w-100" src={`/images/sell-Gadgets.svg`} />
                            <small>Sell Other Gadgets</small>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="p-3 rounded-05rem mb-4" style={{ backgroundColor: 'rgba(100,108,124,0.2)' }}>
                      <ProductSearch />
                    </div>
                    <div className="text-center">
                      <Button className="requestbtn">
                        <Link href="/request-a-quote">
                          <a className="white-link">Request a Price</a>
                        </Link>
                      </Button>
                    </div>
                  </div>
                  <div className="col-md-6 px-lg-5">
                    <img src="/images/sellbnrthmb.webp" alt="" className="w-100 pr-lg-5"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </>
        )}
      </div>
    );
  }
}





export default Searchbar;
